import React, { useContext, useCallback } from "react";
import "react-dropzone-uploader/dist/styles.css";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import Dropzone from "react-dropzone-uploader";

import tokenAuth from "../../config/token";
import clienteAxios from "../../config/axios";

import AlertasContext from "../../context/alertas/alertasContext";
import AuthContext from "../../context/autenticacion/authContext";

const DropZoneComponent = () => {
  const authContext = useContext(AuthContext);
  const {usuarioAutenticado } = authContext;
  // Extraer los valores del context
  const alertaContext = useContext(AlertasContext);
  const { alerta, mostrarAlerta } = alertaContext;

  const FilesMeta = ({ meta }) => {
    return {
      url: "https://httpbin.org/post",
    };
  };

  const onChange = ({ meta, file }, status) => {
    if (status === "headers_received") {
      mostrarAlerta(`${meta.name} subido correctamente!`, "alerta-ok");
    } else if (status === "aborted") {
      mostrarAlerta(`${meta.name}, no se pudo subir...`, "alerta-error");
    } else if (status === "rejected_file_type") {
      mostrarAlerta(
        `Formato de archivo invalido, solo archivos PDF y XML`,
        "alerta-error"
      );
    } else if (status === "rejected_max_files") {
      mostrarAlerta(
        `Solo se puede realizar el envio de dos archivos al mismo tiempo`,
        "alerta-error"
      );
    }
  };

  const onFilesSubmit = async (files, FILES) => {
    usuarioAutenticado();
    const formData = new FormData();
    FILES.forEach((f) => {
      formData.append("archivo", f.file);
    });
    const token = localStorage.getItem("token");
    if (token) {
      tokenAuth(token);
    }
    if (
      (FILES[0].file.name.match(/\.(xml)$/) &&
        FILES[1].file.name.match(/\.(PDF|pdf)$/)) ||
      (FILES[1].file.name.match(/\.(xml)$/) &&
        FILES[0].file.name.match(/\.(PDF|pdf)$/))
    ) {
      let respuesta;
      await clienteAxios
        .post("/api/user/cargar-cfdi", formData, {
          headers: { "Content-Type": "multipart/form-data", "x-token": token },
        })
        .then((result) => {
          respuesta = result;
          mostrarAlerta(`CFDI enviados correctamente!`, "alerta-ok");
          FILES.forEach((f) => f.remove());
        })
        .catch((err) => {
           mostrarAlerta (
            err.response.data.msg,
            "alerta-error",
           );
        });
    } else {
      mostrarAlerta(
        `Error, debe de ser un archivo PDF y uno XML`,
        "alerta-error"
      );
    }
  };

  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };
  const ButtonSubmit = (props) => {
    const { files, onSubmit } = props;
    const bton =
      files.length > 1 ? (
        <div>
          <div
            className="bg-yellow-500 hover:bg-yellow-400 text-white font-bold rounded inline-flex items-center text-center btn"
            type="submit"
          >
            <img src="img/upload_48px.png" className="icon" />
            <span className="font-extrabold">Enviar los CFDI</span>
          </div>
        </div>
      ) : null;
    const handleClick = useCallback(() => {
      if (typeof onSubmit !== "function") {
        return;
      }

      onSubmit(files.filter((f) => f.meta.status === "Done"));
    }, [files, onSubmit]);

    return (
      <label className="btn btn-primary mt-3">
        {bton}
        <button
          type="button"
          tabIndex={0}
          disabled={props.disabled}
          onClick={handleClick}
        ></button>
      </label>
    );
  };
  const selectFileInput = ({ accept, onFiles, files, getFilesFromEvent }) => {
    const button = (
      <div className="bg-yellow-500 hover:bg-yellow-400 text-white font-bold rounded inline-flex items-center text-center btn">
        <img src="img/upload_48px.png" className="icon" />
        Selecciona o arrastra los archivos CFDI para subir
        <br />
        (XML y PDF)
      </div>
    );

    return (
      <label className="btn btn-primary mt-3">
        {button}
        <input
          style={{ display: "none" }}
          type="file"
          accept={accept}
          multiple
          onChange={(e) => {
            getFilesFromEvent(e).then((chosenFiles) => {
              onFiles(chosenFiles);
            });
          }}
        />
      </label>
    );
  };

  return (
    <Dropzone
      onSubmit={onFilesSubmit}
      onChangeStatus={onChange}
      InputComponent={selectFileInput}
      getUploadParams={FilesMeta}
      getFilesFromEvent={getFilesFromEvent}
      accept="application/pdf,text/xml"
      maxFiles={2}
      minFiles={2}
      disabled={(files) =>
        files.some((f) =>
          ["preparing", "getting_upload_params", "uploading"].includes(
            f.meta.status
          )
        )
      }
      SubmitButtonComponent={ButtonSubmit}
      onDropRejected
      styles={{
        dropzone: { width: 500, height: 300 },
        dropzoneActive: { borderColor: "red" },
      }}
    />
  );
};

export default DropZoneComponent;
