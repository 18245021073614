import { OBTENER_CFDIS,CFDI_ERROR } from "../../types";

export default (state, action) => {
  switch (action.type) {
    case OBTENER_CFDIS:
      return {
        ...state,
        cfdi: action.payload,
      };
    case CFDI_ERROR:
      return {
        ...state,
        mensaje: action.payload,
      };
    default:
      return state;
  }
};
