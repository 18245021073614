import React, {useEffect, useContext, } from "react";
import { useNavigate } from "react-router-dom";
import BotonMenu from "../components/BotonMenu";
import Header from '../components/Header';
import AuthContext from "../../context/autenticacion/authContext";
import AlertasContext from "../../context/alertas/alertasContext";

const Menu = () => {
  
  const history = useNavigate();
  const botonCargar = {
    ruta: "/cargar-cfdi",
    clases:
      "bg-yellow-500 hover:bg-yellow-400 text-white font-bold rounded inline-flex items-center btn",
    id: "btn-carga",
    img: "img/upload_document_48px.png",
    span: "Carga de CFDI's",
  };
  const botonConsultar = {
    ruta: "/consultar-cfdi",
    clases:
      "bg-blue-800 hover:bg-blue-700 text-white rounded inline-flex items-center btn",
    id: "btn-consulta",
    img: "img/search_property_48px.png",
    span: "Consulta de CFDI's",
  };

  // Extraer los valores del context
  const alertaContext = useContext(AlertasContext);
  const { alerta, mostrarAlerta } = alertaContext;

  const authContext = useContext(AuthContext);
  const { mensaje, autenticado, usuarioAutenticado, cerrarSesion } = authContext;

// En caso de que el usuario se haya autenticado o alguno de sus datos sea incorrecto
useEffect(() => {
  usuarioAutenticado();
  if(!autenticado) {
    mostrarAlerta('Caduco la sesión', "alerta-error");
      setTimeout(() => {
        history("/");
      }, 1000);
  }
}, [] );

  return (
    <div className="contenedor-menu">
      {alerta ? (
        <div className={`alerta ${alerta.categoria}`}>{alerta.msg}</div>
      ) : null}
      <div className="container mx-auto mt-20 bg-white">
        <header id="header" className="bg-white p-3 font-bold">
        <Header/>
          Menú
        </header>
        <form id="menu-form" className="py-10 px-5 max-w-lg mx-auto">
          <div className="mb-10">
            <BotonMenu props={botonCargar} />
          </div>
          <div className="mb-10">
            <div className="text-align: center">
              <BotonMenu props={botonConsultar} />
            </div>
          </div>
          <button
            className="bg-red-600 hover:bg-red-500 text-white font-bold rounded float-right"
            id="btn-cerrarSesion"
            onClick={() => cerrarSesion()}
          >
            Cerrar Sesión
          </button>
        </form>
      </div>
    </div>
  );
};

export default Menu;
