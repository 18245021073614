import React, { useReducer } from "react";
import {
    OBTENER_USUARIO,
    LOGIN_EXITOSO,
    LOGIN_ERROR,
    CERRAR_SESION,
    OBTENER_CFDIS,
    CFDI_ERROR,
  } from "../../types";
import userContext from "./userContext";
import userReducer from "./userReducer";


import clienteAxios from '../../config/axios';

const UserState = props => {
    const initialState = {
        token: localStorage.getItem('token'),
        autenticado: null,
        usuario: null,
        mensaje: null,
        cfdi:[]
    }

    const [state, dispatch] = useReducer(userReducer, initialState)
    
    // Las funciones
    // Obtener los proyectos
    const obtenerCFDIS = async () => {
        try {

                const token = localStorage.getItem("token");
                  let resultado;
                  await clienteAxios
                    .get("/api/user/consultar-cfdi", {
                      headers: { "Content-Type": "multipart/form-data", "x-token": token },
                    })
                    .then((result) => {
                        resultado = result;
                      dispatch({
                        type: OBTENER_CFDIS,
                        payload: resultado.data.cfdi
                    })
                    })
                    .catch((err) => {
                        const alerta = {
                            msg: 'Hubo un error',
                            categoria: 'alerta-error'
                        }
                        dispatch({
                            type: CFDI_ERROR,
                            payload: alerta
                        })
                    });

            
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            
            dispatch({
                type: CFDI_ERROR,
                payload: alerta
            })
        }
    }
    return (
        <userContext.Provider
            value={{
                token: state.token,
                autenticado: state.autenticado,
                usuario: state.usuario,
                mensaje: state.mensaje,
                cfdi: state.cfdi,
                obtenerCFDIS
            }}
        >{props.children}

        </userContext.Provider>
        
    )
}

export default UserState;