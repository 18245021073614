import React, { useState, useContext, useEffect } from "react";
import AlertasContext from "../../context/alertas/alertasContext";
import AuthContext from "../../context/autenticacion/authContext";
import {useNavigate} from "react-router-dom";

// Ensure you destructure the createBrowserHistory object
import { createBrowserHistory } from 'history';


const Login = (props) => {
  const history = useNavigate();
  // Extraer los valores del context
  const alertaContext = useContext(AlertasContext);
  const { alerta, mostrarAlerta } = alertaContext;

  const authContext = useContext(AuthContext);
  const { mensaje, autenticado, iniciarSesion } = authContext;

// En caso de que el usuario se haya autenticado o alguno de sus datos sea incorrecto
useEffect(() => {
  if(autenticado) {
    history('/dashboard');
  }
  if (mensaje) {
    mostrarAlerta(mensaje.msg, mensaje.categoria);
  }
}, [mensaje, autenticado, history] );

  // State para iniciar sesión
  const [usuario, guardarUsuario] = useState({
    user: "",
    password: "",
  });

  // Extraer usuario
  const { user, password } = usuario;

  const onChange = (e) => {
    guardarUsuario({
      ...usuario,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    // Validamos que no vayan solos
    if (user.trim() === "" || password.trim() === "") {
      mostrarAlerta("Todos los campos son obligatorios", "alerta-error");
      return;
    }
    if (user.trim().length<12||user.trim().length>13) {
      mostrarAlerta("RFC invalido", "alerta-error");
      return;
    }
    iniciarSesion({ rfc: user, password });
  };

  return (
    <div className="container mx-auto mt-20 bg-white">
      {alerta ? (
        <div className={`alerta ${alerta.categoria}`}>{alerta.msg}</div>
      ) : null}
      <header id="header" className="bg-white p-3 font-bold">
        PORTAL DE PROVEEDORES
      </header>

      <form
        id="user-form"
        className="py-10 px-5 max-w-lg mx-auto"
        onSubmit={onSubmit}
      >
        <div className="mb-10">
          <div className="input-user">
            <img src="img/user_24px.png" className="icon" />
            <input
              type="text"
              placeholder="RFC"
              id="user"
              name="user"
              value={user}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="mb-10">
          <div className="input-password">
            <img src="img/lock_24px.png" className="icon" />
            <input
              type="password"
              placeholder="Contraseña"
              id="password"
              name="password"
              value={password}
              onChange={onChange}
            />
          </div>
        </div>
        <div id="cargando" className="hidden">
          <div className="spinner">
            <div className="rect1"></div>
            <div className="rect2"></div>
            <div className="rect3"></div>
            <div className="rect4"></div>
            <div className="rect5"></div>
          </div>
        </div>
        <input type="submit" value="Iniciar Sesión" className="button" />
        <p>Si no cuentas con tus accesos solicitalo a:"Persona Encargada" </p>
      </form>
    </div>
  );
};

export default Login;
