import {
  OBTENER_USUARIO,
  LOGIN_EXITOSO,
  LOGIN_ERROR,
  CERRAR_SESION,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case OBTENER_USUARIO:
        return {
            ...state,
            autenticado: true,
            usuario: action.payload.rfc,
            mensaje: null,
            cargando: false
        }
    case LOGIN_EXITOSO:
      localStorage.setItem('token',action.payload.token);
      return {
          ...state,
          autenticado: true,
          mensaje: null,
          token: action.payload.token,
          cargando: false
      }
    case LOGIN_ERROR:
        localStorage.removeItem('token');
      return {
        ...state,
          token: null,
          mensaje: action.payload,
          autenticado: null,
          cargando: false
      };
    case CERRAR_SESION:
      localStorage.removeItem('token');
      return {
        ...state,
          token: null,
          usuario: null,
          autenticado: null,
          mensaje: action.payload
      };
    default:
      return state;
  }
};
