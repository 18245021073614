import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import Header from "../../components/Header";
import Regresar from "../../components/Back";
import Dropzone from "../../components/Dropzone";

import AlertasContext from "../../../context/alertas/alertasContext";
import AuthContext from "../../../context/autenticacion/authContext";

const CargarCFDI = () => {
  const history = useNavigate();
  // Extraer los valores del context
  const alertaContext = useContext(AlertasContext);
  const { alerta, mostrarAlerta } = alertaContext;

  const authContext = useContext(AuthContext);
  const { mensaje, autenticado, usuarioAutenticado, cerrarSesion } =
    authContext;

  // En caso de que el usuario se haya autenticado o alguno de sus datos sea incorrecto
  useEffect(() => {
    usuarioAutenticado();
    if (!autenticado) {
      mostrarAlerta('Caduco la sesión', "alerta-error");
      setTimeout(() => {
        history("/");
      }, 1000);
    }
  }, []);
  return (
    <div className="contenedor-menu">
      {alerta ? (
        <div className={`alerta ${alerta.categoria}`}>{alerta.msg}</div>
      ) : null}
      <div className="container mx-auto mt-20 bg-white">
        <header id="header" className="bg-white p-3 font-bold">
          <Regresar />
          <Header />
          Cargar
        </header>
            <div className="mb-10 lg:flex md:shadow-lg p-5 bg-white rounded-lg py-10">
              <Dropzone />
            </div>
      </div>
    </div>
  );
};

export default CargarCFDI;
