import React from "react";

const Header = () => {
  return (
    <div className="contenedor align-bottom">
      <img src="img/header.png" className="logo" alt="" />
    </div>
  );
};

export default Header;
