import React, { useReducer } from "react";
import {
  OBTENER_USUARIO,
  LOGIN_EXITOSO,
  LOGIN_ERROR,
  CERRAR_SESION,
} from "../../types";
import authContext from "./authContext";
import authReducer from "./authReducer";

import clienteAxios from "../../config/axios";
import tokenAuth from "../../config/token";

const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem("token"),
    autenticado: null,
    usuario: null,
    mensaje: null,
    cargando: true
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  // Las funciuones
  // Cuando iniciamos sesion
  const iniciarSesion = async (datos) => {
    let respuesta;
    await clienteAxios
      .post("/api/auth", datos)
      .then((result) => {
        respuesta = result;
        dispatch({
          type: LOGIN_EXITOSO,
          payload: respuesta.data,
        });
        // Obtener el usuario
        usuarioAutenticado();
      })
      .catch((err) => {
        const alerta = {
          msg: err.response.data.msg,
          categoria: "alerta-error",
        };
        dispatch({
          type: LOGIN_ERROR,
          payload: alerta,
        });
      });
  };

  const usuarioAutenticado = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      tokenAuth(token);
    }
    try {
      let respuesta;
      await clienteAxios
        .post("/api/auth/auth", { token: token })
        .then((result) => {
          respuesta = result;
          dispatch({
            type: OBTENER_USUARIO,
            payload: respuesta.data,
          });
        });
    } catch (error) {
      dispatch({
        type: LOGIN_ERROR,
      });
    }
  };

  // Cerrar sesion
  const cerrarSesion = () => {
    dispatch({
      type: CERRAR_SESION
    })
  }
  return (
    <authContext.Provider
      value={{
        token: state.token,
        autenticado: state.autenticado,
        usuario: state.usuario,
        mensaje: state.mensaje,
        cargando: state.cargando,
        iniciarSesion,
        usuarioAutenticado,
        cerrarSesion,
      }}
    >
      {props.children}
    </authContext.Provider>
  );
};

export default AuthState;
