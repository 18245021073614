import React, { useContext, useEffect } from 'react';
import { useNavigate, useLocation, Outlet, Navigate } from 'react-router-dom';
import AuthContext from '../../context/autenticacion/authContext';

const RutaPrivada = ({ component: Component, ...props  }) => {
    const authContext = useContext(AuthContext);
    const { autenticado, cargando, usuarioAutenticado } = authContext;

    useEffect(() => {
        usuarioAutenticado();
        // eslint-disable-next-line
    }, []);
    const location = useLocation();
    return autenticado && !cargando? (
      <Outlet />
    ) : (
      <Navigate
        to={`/`}
        replace
        state={{ location }}
      />
    )
}
 
export default RutaPrivada;