import React from "react";
import { Link } from "react-router-dom";

const BotonMenu = () => {
  return (
    <Link
      to="/dashboard"
      className="bg-white hover:bg-grey-100 text-black rounded inline-flex items-center float-left"
    >
      <img src="img/back_24px.png" className="icon" alt="" />
      <span className="font-extrabold">Regresar</span>
    </Link>
  );
};

export default BotonMenu;
