import React from "react";
import { Link } from "react-router-dom";

const BotonMenu = Props => {
    const { props } = Props; 
    const { ruta,clases,id,img,span } = props; 
    return (
      <Link
        to={ruta}
        className={clases}
        id={id}
      >
        <img src={img} className="icon" alt=""/>
        <span className="font-extrabold">{span}</span>
      </Link>
    );
  }

export default BotonMenu;
